<template>
  <sub-page
      :title="$t('Letters')"
      :header-action="openNewNewsletter"
      :header-action-text="$t('AddNewsletter')"
      v-model="$store.state.newsletter.emailPageData"
      header-route="newsletter"
  >

    <template #default>
      <v-sheet class="wsRoundedHalf mt-6 ">
        <ws-data-table
            :items="items"
            :headers="headers"
            hide-default-header
            class="wsHoverLight"

        >
          <template v-slot:item="{item}">
            <tr class="pointer" @click="$router.push(businessDashLink('newsletter/email/view/' + item.uuid))">
              <td>
                <router-link
                    :to="businessDashLink('newsletter/email/view/' + item.uuid)"
                    :style="`color : ${wsACCENT}`"
                    class="linkHover noUnderline"
                >
                  <h5>{{ item.name }}</h5>
                </router-link>

              </td>
              <td width="10px" class="text-no-wrap">
                <ws-chip v-if="item.receivers_type" icon="mdi-account-multiple" :text="$t('receivers_' + item.receivers_type)"></ws-chip>
              </td>
              <td width="10px" class="text-no-wrap">
                <wsChip v-if="item.date_published" icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date_published)"></wsChip>
              </td>
              <td width="10px" >
                <v-chip small outlined :color="!item.is_published ? 'grey' : wsACCENT ">
                  {{ item.is_published ? $t('Published') : $t('Draft')  }}
                </v-chip>
              </td>
              <td class="text-no-wrap">
<!--                <v-btn @click.stop="copyNewsletter(item)" :color="wsACCENT" icon>-->
<!--                  <v-icon>mdi-content-copy</v-icon>-->
<!--                </v-btn>-->
                <v-btn @click.stop="openDelete(item)" :color="wsACCENT" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </ws-data-table>
      </v-sheet>
    </template>

    <template #dialog>
      <wsDialog
          v-model="displayDeleteDialog"
          :title="$t('DeleteNewsletter')"
          :confirm-delete-text="$t('DeleteNewsletterDescription')"
          @delete="deleteNewsletter"
          close-delete
          display-confirm-delete
      />
      <wsDialog v-model="displayDialog"
                :title="$t('AddNewsletter')"
                @save="addNewsletter">

        <h5 class="mb-3">{{$t('Name')}}</h5>
        <ws-text-field
            v-model="entityData.name"
            @input="nameError = false"
            :placeholder="$t('Name')"
            :error="nameError"
            dense outlined
            class="mb-3"
        />

        <ws-button-group
            v-model="entityData.template_type"
            :items="templateTypesSelect"
            :label="$t('newsletter.email.email_design')"
            :description="$t('newsletter.email.email_design_description')"
            class=" mt-5"
        />

        <v-expand-transition>
          <ws-select
              v-if="entityData.template_type !== 'new'"
              v-model="entityData.template"
              :label="$t('Template')"
              :placeholder="$t('SelectTemplate')"
              :items="entityData.template_type === 'template' ? templatesSelect : newslettersSelect"
              class="pt-5"
              :autocomplete="(entityData.template_type === 'template' ? templatesSelect : newslettersSelect).length > 7"
          />
        </v-expand-transition>


      </wsDialog>
    </template>

  </sub-page>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "newslettersTable",
  data() {
    return {
      displayDeleteDialog : false,
      selectedNewsletter : {},
      displayTemplates : false,
      displayDialog : false,
      entityData : {  },
      defaultEntityData : {
        template_type : 'new'
      },
      items : [],
      templatesSelect: [],
      nameError : false
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name')          , value : 'name'          },
        { text : this.$t('ReceiversType') , value : 'receivers_type' },
        { text : this.$t('Published')     , value : 'date_published' },
        { text : this.$t('Status')        , value : 'is_published'   },
        { value : 'action' , sortable : false , width : 50 }
      ]
    },
    templateTypesSelect() {
      return [
        { text : this.$t('New') , value : 'new' },
        { text : this.$t('Template') , value : 'template' },
        { text : this.$t('newsletter.email.email_design_copy') , value : 'existing' },
      ]
    },
    newslettersSelect() {
      let items = []
      this.items.forEach(item => {
        items.push( {
          text : item.name,
          value : item.uuid
        })
      })
      return items
    }
  },
  methods : {
    ...mapActions('newsletter', [
        'GET_NEWSLETTERS',
        'ADD_NEWSLETTER',
        'GET_BUSINESS_NEWSLETTER_TEMPLATES',
        'DELETE_NEWSLETTER'
    ]),

    openDelete(item) {
      this.selectedNewsletter = item
      this.displayDeleteDialog = true
    },
    deleteNewsletter() {
      let result = this.DELETE_NEWSLETTER(this.selectedNewsletter.uuid )
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedNewsletter.uuid)
      if ( index === -1 ) {
        return
      }
      this.items.splice(index , 1 )
      this.displayDeleteDialog = false
      this.notify(this.$t('NewsletterDeleted'))

    },
    copyNewsletter(item) {
      this.console.log(this.$t('NewsletterCopied') + item)
    },

    async getTemplates() {
      if ( this.templatesSelect.length > 0 ) {
        return
      }
      let result = await this.GET_BUSINESS_NEWSLETTER_TEMPLATES()
      if ( !result || result === true ) {
        return
      }
      let items = []
      result.forEach(item => {
        items.push( { text : item.name , value : item.uuid })
      })
      this.templatesSelect = items
    },
    async addNewsletter() {
      if ( !this.entityData.name ) {
        this.nameError = true
        return this.notify(this.$t('EnterName') , 'warning')
      }
      let result = await this.ADD_NEWSLETTER(this.entityData)
      if ( !result ) { return }
      this.items.unshift(result)
      this.displayDialog = false
      this.notify(this.$t('NewsletterCreated') , 'success')
      this.$router.push(this.businessDashLink('newsletter/email/view/' + result.uuid))
    },

    openNewNewsletter() {
      this.getTemplates()
      this.entityData = JSON.parse(JSON.stringify(this.defaultEntityData))
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_NEWSLETTERS()
      if ( !result || result === true) { return }
      result.reverse()
      this.items = result
      this.displayDialog = false
    }

  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>